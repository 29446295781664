// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #09468D;
$primary_alt: #0A53A6;

$accent: #09468D;
$accent_alt: #0A53A6;

$light: #FFFFFF;
$light_alt: #EEEEEE;

$dark: #000000;
$dark_alt: #303030;

$foreground: #303030;
$foreground_alt: #000000;

$background: #FFFFFF;
$background_alt: #979797;

$page_background: #FFFFFF;
$page_background_alt: #979797;

$warning: #9F0000;
$warning_alt: #F83636;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "baskerville-urw", "Times", "Times New Roman", serif;
$body-font: "adelle-sans", "Helvetica", "Arial", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1440;
$column-gap: 50;
$content-padding: 50;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    header: 5,
    navigation: 4,
    hero: 3,
    content: 2,
    footer: 1,
);
