// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Functions
\* ------------------------------------------------------------------------ */

// vendor
@import "../vendor/_choose-contrast-color";
@import "../vendor/_inline-svg-function";

// Remove the unit of a length
// @param  {Number}  $number
@function strip-unit($number) {
    @if type-of($number) == "number" and not unitless($number) {
        $number: $number / ($number * 0 + 1);
    }

    @return $number;
}

// Remify
// @param  {Number}  $size - Array of up to four sizes in PX units
// @param  {Number}  $base - Font size to base calculations off of
// @param  {String}  $unit - Either "em" or "rem"
@function remify($sizes: ($size1, $size2, $size3, $size4), $base: null, $unit: null) {
    $unit: if($unit == null, if($base == null, rem, em), $unit);
    $base: if($base == null, 16, strip-unit($base));
    $output: null;

    @each $size in $sizes {
        $size: strip-unit($size);

        @if type-of($size) == "number" {
            // add as space if $output already has a value
            $output: if($output == null, "", $output + " ");
            // calculate, append value to $output
            $output: $output + ((strip-unit($size) / strip-unit($base)) + $unit);
        }

        @else {
            // pass the value through unchagned if $size is not a number
            $output: $output + " " + $size;
        }
    }

    @return if(type-of($output) == "string", unquote($output), $output);
}

// Photoshop Leading => CSS Line Height Conversion
// @param  {Number}  $size - Value of Leading in Photoshop's Character Panel
// @param  {Number}  $base - Value of Font Size in Photosohp's Character Panel
@function leading($size, $base: null) {
    $base: if($base == null, 16, strip-unit($base));
    $output: $base + (strip-unit($size) / 2);

    @return $output;
}

// Photoshop Tracking => CSS Letter Spacing Conversion
// @param  {Number}  $size - Value of Tracking in Photoshop's Character Panel
// @param  {Number}  $base - Value of Font Size in Photosohp's Character Panel
@function tracking($size, $base: null) {
    $base: if($base == null, 16, strip-unit($base));
    $output: ($size / 1000) * strip-unit($base);

    @return $output;
}

// Specify size relative to a value
// @param  {Number}  $size - Target size in pixels
// @param  {Number}  $base - Base size in pixels
@function relative-size($size, $base) {
    @return $base * ($size / $base);
}
