// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Page
\* ------------------------------------------------------------------------ */

.page_container {
    & {
        padding-top: remify($content-padding * 5.3);
    }
}
