// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }
}

.footer_inner {
    & {
        background: $page_background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 1.25) $content-padding);
    }
}

.footer_text {
    &.-table .text_cell.-header {
        text-align: right;
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        background: $page_background;
        padding: remify(($content-padding * 1.25) $content-padding);
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}
