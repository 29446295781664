// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */

/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */

@import "../layout/page/_page_xs";
@import "../layout/header/_header_xs";
@import "../layout/content/_content_xs";
@import "../layout/footer/_footer_xs";
